const { Modals } = await import("@/controller/modals");

const { DATA_PROP_IMAGE_GALLERY_TRIGGER, DATA_ATTR_IMAGE_GALLERY_ITEM, MODAL_ID_PREFIX } = await import("@/controller/image-gallery");

const DATA_ATTR_IMAGE_GALLERY_SCROLL = "data-image-gallery-scroll";
const DATA_PROP_IMAGE_GALLERY_SCROLL = "imageGalleryScroll";

export class ImageGalleryScroll {
    private static instance: ImageGalleryScroll;

    public static getInstance(): ImageGalleryScroll {
        if (!ImageGalleryScroll.instance) {
            ImageGalleryScroll.instance = new ImageGalleryScroll();
        }

        return ImageGalleryScroll.instance;
    }

    private constructor() {
        const modalMap = Modals.getInstance().modalMap;

        const galleries = document.querySelectorAll(`[${DATA_ATTR_IMAGE_GALLERY_SCROLL}]`) as NodeListOf<HTMLElement>;

        galleries.forEach(gallery => {
            const uid = gallery.dataset[DATA_PROP_IMAGE_GALLERY_SCROLL];

            if (!uid) {
                throw new Error(`${DATA_PROP_IMAGE_GALLERY_SCROLL} not found in gallery dataset`);
            }

            const modal = modalMap[MODAL_ID_PREFIX + uid];
            modal.addEventListener("show", (trigger) => {
                const scrollToNumber = Number.parseInt(trigger.dataset[DATA_PROP_IMAGE_GALLERY_TRIGGER] || "1");
                if (scrollToNumber > 1) {
                    const scrollToElement = modal.element.querySelector(`[${DATA_ATTR_IMAGE_GALLERY_ITEM}="${scrollToNumber}"]`);
                    scrollToElement?.scrollIntoView();
                }
            });
        });
    }
}
